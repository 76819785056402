import React, { ReactElement } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'theme-ui';

import useMedia from 'react-use/lib/useMedia';
import { useHasMounted } from '../../../../../utils/useHasMounted';

const BrandSelectBackground = ({ image }): ReactElement => {
  const isDesktop = useMedia('(min-width: 768px)');
  const { asset, mobileAsset } = image;

  return (
    <GatsbyImage
      alt="report"
      image={isDesktop ? asset.gatsbyImageData : mobileAsset.gatsbyImageData}
    />
  );
};

const BrandSelectBackgroundWrapper = ({ image }) => {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return (
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 1,
          backgroundColor: '#EEEFF1',
        }}
      />
    );
  }

  return <BrandSelectBackground image={image} />;
};

export { BrandSelectBackgroundWrapper as BrandSelectBackground };
