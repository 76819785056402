import { Option } from './types';

const PAGE_SIZE = 20;

export const loadOptions = async (
  search = '',
  loadedOptions: readonly Option[] = [],
): Promise<{
  options: Option[];
  hasMore: boolean;
}> => {
  const response = await fetch('https://external-prod12.sprinklr.com/rest/selfserve/brands', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      page: { page: loadedOptions.length / PAGE_SIZE, size: PAGE_SIZE },
      query: search,
    }),
  }).then(res => res.json());
  return {
    options: response.result.map(({ id, name, logo }) => ({ value: id, label: name, logo })),
    hasMore: response.hasMore,
  };
};
