import React, { ReactElement } from 'react';

import { Box, Spinner, Text } from 'theme-ui';
import { Avatar } from '@sprinklr/shared-lib/components/avatar/AvatarWithInitials';
import { components, SingleValueProps, OptionProps } from 'react-select';
import Button from '@sprinklr/shared-lib/components/button';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import QuickSearchIcon from '@sprinklr/shared-lib/assets/svgs/quickSearch.svg';

import { Option as OptionT } from './types';

export const STYLES = {
  menuPortal: base => ({ ...base, zIndex: 999999999999999 }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: base => ({
    ...base,
    borderRadius: '8px',
  }),
  menuList: base => ({
    ...base,
    maxHeight: '222px',
  }),
};

const Value = ({
  logo,
  label,
}: {
  logo: string;
  label: string;
}): ReactElement => (
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Avatar src={logo} size={24} name={label} />
    <Text
      sx={{
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        marginLeft: '8px',
      }}
    >
      {label}
    </Text>
  </Box>
);

export const Option = (props: OptionProps<OptionT>): ReactElement => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <Value logo={data.logo} label={data.label} />
    </components.Option>
  );
};

export const SingleValue = (props: SingleValueProps<OptionT>): ReactElement => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <Value logo={data.logo} label={data.label} />
    </components.SingleValue>
  );
};

export const LoadingIndicator = (): ReactElement => <Spinner size={24} />;

export const NoOptionsMessage = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        padding: '24px',
      }}
    >
      <Box
        sx={{
          height: '54px',
          width: '54px',
          borderRadius: '100%',
          backgroundColor: 'lightGrey',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <QuickSearchIcon height="24px" width="24px" />
      </Box>
      <Text
        sx={{
          fontWeight: 500,
          fontSize: '14px',
          textAlign: 'center',
          lineHeight: '20px',
          marginTop: '4px',
        }}
      >
        {t(
          'Can’t find your brand here? Submit a request and we’ll generate your report and get back to you.',
        )}
      </Text>
      <Button
        type="primary"
        onClick={onClick}
        buttonSx={{ marginTop: '12px', height: '32px' }}
      >
        <Text sx={{ textTransform: 'none' }}>{t('Request report')}</Text>
      </Button>
    </Box>
  );
};
