import { useState, useLayoutEffect } from 'react';

export const useClientTop = () => {
  const [el, setEl] = useState();
  const [top, setTop] = useState(0);

  useLayoutEffect(() => {
    if (el) {
      setTop(el.getBoundingClientRect().top);
    }
  }, [el]);

  return { ref: setEl, top, el };
};
