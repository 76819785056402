import { useMemo } from 'react';

export const AVATAR_COLORS = ['#9c60ff', '#00c8ff', '#ffbb61', '#90d83e', '#eec202', '#13c18d', '#16aad2'];

const getRandomBgColor: () => string = () => AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)];

//NOTE: Hash logic based on https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
const getColorForName = name => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < name.length; i++) {
    const chr = name.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // eslint-disable-line no-bitwise
    hash |= 0; // eslint-disable-line no-bitwise
  }
  return AVATAR_COLORS[Math.abs(hash) % (AVATAR_COLORS.length - 1)];
};

export const useAvatarColor: (string) => string = name =>
  useMemo(() => (name ? getColorForName(name) : getRandomBgColor()), [name]);
