import React, { useCallback, useState, ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import Modal from '@sprinklr/shared-lib/components/modal';
import { Box, Heading, Text } from 'theme-ui';
import Button from '@sprinklr/shared-lib/components/button';
import { BrandAsyncSelect } from './brandAsyncSelect/BrandAsyncSelect';
import DismissButton from '@sprinklr/shared-lib/components/dismissButton';
import FormTemplate from '../../../formTemplate';

import { resolveLocale } from '@sprinklr/shared-lib/utils';

type BrandSelectModalProps = {
  isOpen: boolean;
  onSubmit: (brand: { value: string }) => void;
  title: string;
};

export const BrandSelectModal = ({
  isOpen,
  onSubmit,
  title,
}: BrandSelectModalProps): ReactElement => {
  const { t, language } = useI18next();
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [brandAddForm, setBrandAddForm] = useState(false);
  const [isFromLoaded, setIsFormLoaded] = useState(false);
  const handleSubmit = useCallback(
    () => onSubmit(selectedBrand),
    [onSubmit, selectedBrand],
  );

  const closeBrandAddForm = useCallback(() => setBrandAddForm(false), []);

  const openBrandAddForm = useCallback(() => setBrandAddForm(true), []);

  const data = useStaticQuery(graphql`
    {
      form: allContentfulTemplateForm(
        filter: { uniqueFormUseCaseId: { eq: "USER_BRAND_ADD" } }
      ) {
        nodes {
          ...ContentfulTemplateFormNode
        }
      }
    }
  `);

  const nodeLocale = resolveLocale(language, true, 'en');

  const formData = data?.form?.nodes?.find(
    form => form.node_locale === nodeLocale,
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeBrandAddForm}
      modalOverlaySx={{ flexDirection: 'column', padding: 0 }}
      modalContentSx={{
        minHeight: 0,
        position: 'initial',
        overflow: 'none',
      }}
    >
      {brandAddForm ? (
        <Box sx={{ position: 'relative' }}>
          <FormTemplate {...formData} onLoad={setIsFormLoaded} />
          {isFromLoaded ? (
            <DismissButton
              closeFn={closeBrandAddForm}
              dismissSx={{
                top: '48px',
                right: '28px',
              }}
            />
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'white',
            width: ['362px', '562px'],
            padding: '32px',
            borderRadius: '16px',
          }}
        >
          <Heading sx={{ fontSize: '26px', fontWeight: 700 }}>{title}</Heading>
          <Box sx={{ marginTop: '32px' }}>
            <BrandAsyncSelect
              value={selectedBrand}
              onChange={setSelectedBrand}
              openBrandAddForm={openBrandAddForm}
            />
          </Box>
          <Button
            type="primary"
            onClick={handleSubmit}
            buttonSx={{ marginTop: '32px', width: '100%' }}
            disabled={!selectedBrand}
          >
            <Text sx={{ textTransform: 'none' }}>{t('Continue')}</Text>
          </Button>
        </Box>
      )}
    </Modal>
  );
};
