import React, { useLayoutEffect, useState, ReactElement } from 'react';
import { useAvatarColor } from './useAvatarColor';
import { Box, Avatar as BaseAvatar } from 'theme-ui';

function getInitials(name) {
  const words = name.split(' ');
  const initials = words.map(word => word[0]);
  return initials
    .slice(0, 2)
    .join('')
    .toUpperCase();
}

const getFontSize = (size: number): string => {
  if (size < 24) return '8px';
  else if (size < 36) return '12px';
  else if (size < 48) return '16px';
  else if (size < 60) return '20px';
  else if (size < 72) return '24px';
  else if (size < 84) return '28px';
  else return '36px';
};

const Initials = ({ size, name }: {
  size: number;
  name: string;
}):ReactElement => {
  const backgroundColor = useAvatarColor(name);
  return <Box sx={{
    height: size,
    width: size,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    alignItems: 'center',
    color: 'white',
    borderRadius: '100%',
    backgroundColor,
    fontSize: getFontSize(size)
  }}>
    {getInitials(name)}
  </Box>;
};

export const Avatar = ({ src, size, name }: {
  src: string;
  size: number;
  name: string;
}):ReactElement  => {
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useLayoutEffect(() => {
    if (!imageRef || !src) {
      setImageLoaded(false);
      return undefined;
    }
    if (imageRef) {
      imageRef.onload = () => setImageLoaded(true);
      imageRef.onerror = () => setImageLoaded(false);
      /**
       * Important to set src once onload listener is attached.
       * In some cases, image loads before these listeners are attached.
       */
      imageRef.src = src;
    }
    return () => {
      if (imageRef) {
        imageRef.onload = null;
        imageRef.onerror = null;
      }
    };
  }, [src, imageRef]);

  return <>
    <BaseAvatar src={src} height={size} width={size} ref={setImageRef}
                sx={{ display: imageLoaded ? 'block' : 'none' }} />
    {imageLoaded ? null : <Initials size={size} name={name} />}
  </>;
};