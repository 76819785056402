import React, { useCallback, useLayoutEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { useClientTop } from '../../../utils/useClientTop';
import useWindowSize from 'react-use/lib/useWindowSize';

import { BrandSelectBackground } from './components/brandSelectBackground';
import { BrandSelectModal } from './components/brandSelectModal';

const VALID_ORIGIN_REGEX = /(.*)\.sprinklr\.com/;

const isFromCorrectOrigin = origin => VALID_ORIGIN_REGEX.test(origin);

const BrandReportTemplate = ({
  iframeSource,
  backgroundImage,
  name,
  location,
}: {
  location: any;
  iframeSource: string;
  name: string;
}) => {
  const { ref, top } = useClientTop();
  const { height: windowHeight } = useWindowSize();

  const [iframeHeight, setIframeHeight] = useState();
  const params = new URLSearchParams(location?.search);
  const companyId = params.get('companyId') ?? undefined;

  const onSubmit = useCallback(
    option => {
      navigate(`${location.pathname}?companyId=${option.value}`);
    },
    [location],
  );

  const [iframeSrc, queryParams] = iframeSource.split('?');

  useLayoutEffect(() => {
    window.addEventListener('message', event => {
      if (
        isFromCorrectOrigin(event.origin) &&
        event.data.type === 'SET_HEIGHT'
      ) {
        setIframeHeight(event.data.height);
      }
    });
  }, []);

  return (
    <>
      <div ref={ref}>
        {companyId ? (
          <iframe
            height={iframeHeight ? iframeHeight : windowHeight - top}
            width="100%"
            src={`${iframeSrc}/${companyId}?${queryParams ?? ''}`}
            frameBorder="0"
            scrolling="no"
            style={{ opacity: iframeHeight ? 1 : 0 }}
          />
        ) : (
          <BrandSelectBackground image={backgroundImage} />
        )}
      </div>
      <BrandSelectModal isOpen={!companyId} onSubmit={onSubmit} title={name} />
    </>
  );
};

export default BrandReportTemplate;
