import React, { useCallback, ReactElement, useMemo } from 'react';

import { AsyncPaginate } from 'react-select-async-paginate';
import { Label } from 'theme-ui';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { loadOptions } from './brandsQuery';

import {
  STYLES,
  Option,
  SingleValue,
  LoadingIndicator,
  NoOptionsMessage,
} from './overrides';

import { Option as OptionT } from './types';

type BrandAsyncSelectProps = {
  value: OptionT;
  onChange: (value: OptionT) => void;
  openBrandAddForm: () => void;
};
export const BrandAsyncSelect = ({
  value,
  onChange,
  openBrandAddForm,
}: BrandAsyncSelectProps): ReactElement => {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (option, { action }) => {
      if (action === 'select-option') {
        onChange(option);
      }
    },
    [onChange],
  );

  const components = useMemo(
    () => ({
      Option,
      SingleValue,
      LoadingIndicator,
      NoOptionsMessage: () => <NoOptionsMessage onClick={openBrandAddForm} />,
    }),
    [openBrandAddForm],
  );

  return (
    <>
      <Label
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: 'darkGrey',
          marginBottom: '4px',
        }}
      >
        {t('Pick your company here to see how you fare')}
      </Label>
      <AsyncPaginate
        defaultOptions
        loadOptionsOnMenuOpen={true}
        placeholder={t('Enter your Company’s name')}
        value={value}
        onChange={handleChange}
        loadOptions={loadOptions}
        menuPortalTarget={
          typeof window !== 'undefined' ? document.body : undefined
        }
        styles={STYLES}
        components={components}
      />
    </>
  );
};
